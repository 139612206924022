import { formataData2, formataHora } from '../../util/maskdoc';
import { useContext, useState } from 'react';
import ModalInfoOs from './modal-info-os';
import { UsuarioContext } from '../../context/userContext.jsx';
import api from '../../service/api.js';
import Swal from 'sweetalert2';
import { toast } from '../../constants/toast.jsx';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { MdOutlineSpeakerNotes } from 'react-icons/md';
import { useQueryClient } from '@tanstack/react-query';

function CardOsFinalizadas(props) {
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [situacaoProps, setSituacaoProps] = useState(props.infos.situacao);

  const { nomeUsuario, idUsuario, isAdminUsuario, setorUsuario } = useContext(UsuarioContext);
  const situacaoObjeto = {
    F: <small className="badge text-bg-success mx-1 span-situacao ">Finalizado</small>,
    E: <small className="badge text-bg-secondary mx-1 span-situacao ">Testes</small>,
    A: <small className="badge text-bg-info mx-1 span-situacao ">Atualizar</small>,
    P: <small className="badge text-bg-danger mx-1 span-situacao ">Pendente</small>,
    W: <small className="badge text-bg-primary mx-1 span-situacao ">Aguardando</small>,
    T: <small className="badge text-bg-warning mx-1 span-situacao ">Desenvolvendo</small>,
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  async function RequisicaoAtualizacaoOS(e, aprovado) {
    e.stopPropagation();

    if (Number(idUsuario) === Number(props.infos.id_equipe_suporte) && isAdminUsuario !== 'S') {
      return showErrorToast('Sem permissão de realizar operação');
    }
    let dadosParaRequisicao;

    if (aprovado) {
      dadosParaRequisicao = osAprovada(props.infos);
    } else {
      dadosParaRequisicao = await osNegada(props.infos);
      if (!dadosParaRequisicao) return;
    }

    const result = await req(dadosParaRequisicao);

    if (result.status === 200) {
      if (aprovado) {
        setSituacaoProps(dadosParaRequisicao.situacao);
        props.infos.situacao = dadosParaRequisicao.situacao;
        props.infos.ocorrencia = dadosParaRequisicao.ocorrencia;
      }
      if (!aprovado) {
        props.removerOsdaListagemNegar(props.infos.id);
      }
      showSuccessToast();
    } else {
      showErrorToast(result.data);
    }
  }

  function osAprovada(currentSituacao) {
    const data = new Date().toISOString();
    let situacao;
    let updateMessage = `\n${formataData2(data)} ${formataHora(data)} | ${nomeUsuario} | `;
    if (currentSituacao.situacao === 'E') {
      situacao = 'A';
      updateMessage += `${nomeUsuario} validou a OS e liberou para o status Atualizar.`;
    } else if (currentSituacao.situacao === 'A') {
      situacao = 'F';
      updateMessage += `${nomeUsuario} atualizou o cliente com a OS Finalizado.`;
    }

    const ocorrencia = currentSituacao.ocorrencia + updateMessage;

    return { situacao, ocorrencia };
  }

  async function osNegada(currentSituacao) {
    const situacao = 'P';
    const data = new Date().toISOString();
    const solucaoAnterior = `\n${formataData2(currentSituacao.data_fechamento)} ${formataHora(currentSituacao.data_fechamento)} | ${
      currentSituacao.encaminhado
    } | Solução anterior: ${props.infos.solucao}`;

    const motivoRetorno = await promptUserForMotivo();
    if (!motivoRetorno) {
      return null;
    }

    const updateMessage = `\n${formataData2(data)} ${formataHora(data)} | ${nomeUsuario} | ${motivoRetorno}`;

    const ocorrencia = currentSituacao.ocorrencia + solucaoAnterior + updateMessage;

    return { situacao, ocorrencia, resetOs: 'S' };
  }

  async function promptUserForMotivo() {
    const { value: motivo } = await Swal.fire({
      title: 'Retornar a OS.',
      text: 'Informe o motivo para o retorno.',
      input: 'textarea',
      inputValidator: (value) => {
        if (!value) {
          return 'Precisa informar um motivo.';
        }
      },
      showDenyButton: true,
      denyButtonText: 'Não',
      confirmButtonText: 'Sim',
    });

    return motivo;
  }

  function showSuccessToast() {
    toast.fire({
      icon: 'success',
      title: 'OS atualizada com sucesso.',
    });
  }

  function showErrorToast(message) {
    toast.fire({
      icon: 'error',
      title: 'Houve algum problema',
      text: message,
    });
  }

  function AdicionarRegistroOcorrencia(e) {
    e.stopPropagation();
    Swal.fire({
      title: 'Adicionar Registro de Ocorrência',
      text: `Número: ${props.infos.id} - Cliente: ${props.infos.cliente}`,
      input: 'textarea',
      inputValidator: (value) => {
        if (!value) {
          return 'Precisa informar algum registro.';
        }
        if (value.length > 300) {
          return 'O registro não pode ter mais de 300 caracteres.';
        }
      },
      inputAttributes: {
        maxlength: 300,
      },
      showDenyButton: true,
      denyButtonText: 'Fechar',
      confirmButtonText: 'Enviar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = new Date().toISOString();
        let updateMessage = `\n${formataData2(data)} ${formataHora(data)} | ${nomeUsuario} | ${result.value}`;
        const ocorrencia = props.infos.ocorrencia + updateMessage;
        const resp = await req({ ocorrencia });
        if (resp.status === 200) {
          showSuccessToast();
          queryClient.invalidateQueries({
            queryKey: ['ListaOsFinalizadas'],
          });
        } else {
          showErrorToast(resp.data);
        }
      }
    });
  }

  async function req({ situacao, ocorrencia, resetOs }) {
    const { id, id_cliente, id_equipe_lancamento, id_equipe_suporte, tipo, tipo_os } = props.infos;
    return api.patch('/v1/atendimentos', {
      id,
      id_cliente,
      id_equipe_lancamento,
      id_equipe_suporte,
      tipo_os,
      tipo,
      situacao,
      ocorrencia,
      resetOs,
    });
  }

  return (
    <div className="container">
      <ModalInfoOs isOpen={modalIsOpen} onRequestClose={closeModal} info={props.infos} />
      <div className="card-cliente-container" onClick={() => setModalIsOpen(true)}>
        <div className={`tag-card-cliente-ativo`}></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row align-items-center">
                <div className="col-12 col-sm-6 col-md-3 col-xl-3" style={{ overflow: 'hidden' }}>
                  <span>
                    Numero: <strong>{props.infos.id}</strong>
                  </span>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-xl-5" style={{ overflow: 'hidden' }}>
                  <span>
                    Cliente: <strong>{props.infos.cliente}</strong>
                  </span>
                </div>

                <div className="col-12 col-sm-6 col-md-5 col-xl-4" style={{ overflow: 'hidden' }}>
                  <span>
                    Lançamento: {formataData2(props.infos.data_lancamento)} {formataHora(props.infos.data_lancamento)}
                  </span>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-12 col-sm-6 col-md-6 col-xl-3" style={{ overflow: 'hidden' }}>
                  <span>
                    Lançado: <strong>{props.infos.lancamento}</strong>
                  </span>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-xl-5" style={{ overflow: 'hidden' }}>
                  <span>
                    Encaminhado: <strong>{props.infos.encaminhado}</strong>
                  </span>
                </div>
                <div className="col-12 col-sm-6 col-md-5 col-xl-4" style={{ overflow: 'hidden' }}>
                  <span>
                    Fechamento: {formataData2(props.infos.data_fechamento)} {formataHora(props.infos.data_fechamento)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row h-100">
                <div className="col-6 d-flex align-items-center justify-content-start">Situação: {situacaoObjeto[situacaoProps]}</div>
                <div className="col-6 row row-cols-3">
                  {setorUsuario && setorUsuario === 'D' && props.infos.id_equipe_lancamento !== idUsuario ? null : ['P', 'W', 'T'].includes(
                      situacaoProps
                    ) ? null : !['F'].includes(situacaoProps) ? (
                    <>
                      <button
                        className={`btn btn-outline-success  border-0`}
                        title="Aprovar"
                        onClick={(e) => RequisicaoAtualizacaoOS(e, true)}
                      >
                        <FaCircleCheck />
                      </button>

                      {!['A', 'F'].includes(situacaoProps) ? (
                        <button
                          className={`btn btn-outline-danger  border-0`}
                          title="Negar"
                          onClick={(e) => RequisicaoAtualizacaoOS(e, false)}
                        >
                          <FaCircleXmark />
                        </button>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ) : null}
                  {(Number(idUsuario) === Number(props.infos.id_equipe_suporte) ||
                    Number(idUsuario) === Number(props.infos.id_equipe_lancamento)) &&
                  !['F'].includes(situacaoProps) ? (
                    <button
                      className={`btn btn-outline-primary  border-0`}
                      title="Adicionar Registro"
                      onClick={AdicionarRegistroOcorrencia}
                    >
                      <MdOutlineSpeakerNotes />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardOsFinalizadas;

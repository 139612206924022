import PrivateRoute from '../components/private-route';
import Ajuste from '../components/registro-horario/ajuste/index.jsx';
import Calendario from '../components/registro-horario/calendario/index.jsx';
import Expediente from '../components/registro-horario/expediente/index.jsx';
import Registro from '../components/registro-horario/registro/index.jsx';
import { BuscaClienteListaAutoCompleteProvider } from '../context/BuscaClienteListaAutoCompleteContext.jsx';
import Page404 from '../pages/404';
import RegistroDeHorario from '../pages/RegistroDeHorario';
import OsFinalizadas from '../pages/atendimentos/OSfinalizadas';
import AtendimentoEdicao from '../pages/atendimentos/edicao';
import Main from '../pages/atendimentos/lista';
import ClienteEdicao from '../pages/clientes/edicao';
import ListaClientes from '../pages/clientes/lista';
import PermissoesPage from '../pages/configuracoes/permissoes-usuario/index.jsx';
import ConfigUsuario from '../pages/configuracoes/usuario-config/index.jsx';
import Dashboard from '../pages/dashboard';
import ConciliacaoCliente from '../pages/financeiro/conciliacao-cliente/index.jsx';
import ConferenciaRecebimentos from '../pages/financeiro/conferencia-recebimentos/index.jsx';
import Login from '../pages/login';
import ListaParam from '../pages/param/index.jsx';
import PerfilUsuario from '../pages/perfilUsuario';
import AtendimentoPorEquipe from '../pages/relatorios/AtendimentoPorEquipe/index.jsx';
import PicoDeAtendimentos from '../pages/relatorios/PicoAtendimento/index.jsx';
import AtendimentoPorCliente from '../pages/relatorios/atendimentoPorCliente/index.jsx';
import UsoSiaac from '../pages/usosiaac';
import UsoSiaacDispositivos from '../pages/usosiaac-dispositivos';
import UsuariosEdicao from '../pages/usuarios/edicao';
import ControleFerias from '../pages/usuarios/ferias/index.jsx';
import ListaUsuarios from '../pages/usuarios/lista';
import ListagemClientesPorDtCadastro from '../pages/relatorios/ListagemClientesPorDtCadastro/index.jsx';
import AtendimentoPorTipo from '../pages/relatorios/atendimentoPorTipo/index.jsx';

export const ROUTE_HOME = '/';
export const ROUTE_ATENDIMENTOS = '/atendimentos';
export const ROUTE_ATENDIMENTO_EDICAO = '/atendimentos/:id';
export const ROUTE_OS_FINALIZADAS = '/atendimentos/OsFinalizadas';
export const ROUTE_LOGIN = '/login';
export const ROUTE_CLIENTES = '/clientes';
export const ROUTE_CLIENTE_EDICAO = '/clientes/:id';
export const ROUTE_USUARIOS = '/usuarios';
export const ROUTE_USUARIO_EDICAO = '/usuarios/:id';
export const ROUTE_USUARIOS_FERIAS = '/usuarios/ferias';
export const ROUTE_HORARIOS = '/horarios';
export const ROUTE_USOSIAAC = '/usosiaac';
export const ROUTE_USOSIAAC_DISPOSITIVO = '/usosiaac/:contrato/:empresa';
export const ROUTE_CONFIGURACOES = '/configuracoes';
export const ROUTE_CONFIGURACOES_PERMISSOES = '/configuracoes/permissoes';
export const ROUTE_CONFIGURACOES_USUARIO = '/configuracoes/usuario';
export const ROUTE_PERFIL = '/perfil';
export const ROUTE_404 = '*';
export const ROUTE_LISTA_PARAM = '/clientes/lista_param';
export const ROUTE_FINANCEIRO = '/financeiro';
export const ROUTE_FINANCEIRO_CONFERENCIA = '/financeiro/recebimento/liste';
export const ROUTE_FINANCEIRO_CONCILIACAO = '/financeiro/conciliacao/liste';
export const ROUTE_RELATORIO = '/relatorio';
export const ROUTE_RELATORIO_ATENDIMENTO_POR_EQUIPE = '/relatorio/atendimento-por-equipe';
export const ROUTE_RELATORIO_ATENDIMENTO_POR_CLIENTE = '/relatorio/atendimento-por-cliente';
export const ROUTE_RELATORIO_ATENDIMENTO_POR_TIPO = '/relatorio/atendimento-por-tipo';
export const ROUTE_RELATORIO_PICO_ATENDIMENTO = '/relatorio/pico-atendimento';
export const ROUTE_RELATORIO_CLIENTES_DATA_CADASTRO = '/relatorio/clientes-cod-financeiro';

const routes = [
  {
    path: ROUTE_HOME,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    key: 'home',
  },
  {
    path: ROUTE_ATENDIMENTOS,
    element: (
      <PrivateRoute>
        <Main />
      </PrivateRoute>
    ),
    key: 'atendimentos',
  },
  {
    path: ROUTE_ATENDIMENTO_EDICAO,
    element: (
      <PrivateRoute>
        <AtendimentoEdicao />
      </PrivateRoute>
    ),
    key: 'atendimento edição',
  },
  {
    path: ROUTE_OS_FINALIZADAS,
    element: (
      <PrivateRoute>
        <OsFinalizadas />
      </PrivateRoute>
    ),
    key: 'os finalizadas',
  },
  {
    path: ROUTE_LOGIN,
    element: <Login />,
    key: 'login',
  },
  {
    path: ROUTE_CLIENTES,
    element: (
      <PrivateRoute>
        <ListaClientes />
      </PrivateRoute>
    ),
    key: 'lista clientes',
  },
  {
    path: ROUTE_HORARIOS,
    element: (
      <PrivateRoute>
        <RegistroDeHorario />
      </PrivateRoute>
    ),
    key: 'registro de horario',
    children: [
      {
        path: ROUTE_HORARIOS,
        element: <Expediente />,
        key: 'expediente',
      },
      {
        path: ROUTE_HORARIOS + '/registros',
        element: <Registro />,
        key: 'registros',
      },
      {
        path: ROUTE_HORARIOS + '/ajuste',
        element: <Ajuste />,
        key: 'ajuste',
      },
      {
        path: ROUTE_HORARIOS + '/calendario',
        element: <Calendario />,
        key: 'calendario',
      },
    ],
  },
  {
    path: ROUTE_CLIENTE_EDICAO,
    element: (
      <PrivateRoute>
        <ClienteEdicao />
      </PrivateRoute>
    ),
    key: 'edicao clientes',
  },
  {
    path: ROUTE_USUARIOS,
    element: (
      <PrivateRoute>
        <ListaUsuarios />
      </PrivateRoute>
    ),
    key: 'usuarios',
  },
  {
    path: ROUTE_USUARIO_EDICAO,
    element: (
      <PrivateRoute>
        <UsuariosEdicao />
      </PrivateRoute>
    ),
    key: 'usuario edicao',
  },
  {
    path: ROUTE_PERFIL,
    element: (
      <PrivateRoute>
        <PerfilUsuario />
      </PrivateRoute>
    ),
    key: 'usuario perfil',
  },
  {
    path: ROUTE_USOSIAAC,
    element: (
      <PrivateRoute>
        <UsoSiaac />
      </PrivateRoute>
    ),
    key: 'uso siaac',
  },
  {
    path: ROUTE_USOSIAAC_DISPOSITIVO,
    element: (
      <PrivateRoute>
        <UsoSiaacDispositivos />
      </PrivateRoute>
    ),
    key: 'uso siaac dispositivos',
  },
  {
    path: ROUTE_CONFIGURACOES_PERMISSOES,
    element: (
      <PrivateRoute>
        <PermissoesPage />
      </PrivateRoute>
    ),
    key: 'permissoes',
  },
  {
    path: ROUTE_404,
    element: <Page404 />,
    key: '404 not found',
  },
  {
    path: ROUTE_LISTA_PARAM,
    element: (
      <PrivateRoute>
        <ListaParam />
      </PrivateRoute>
    ),
    key: 'lista param',
  },
  {
    path: ROUTE_FINANCEIRO_CONFERENCIA,
    element: (
      <PrivateRoute>
        <BuscaClienteListaAutoCompleteProvider>
          <ConferenciaRecebimentos />
        </BuscaClienteListaAutoCompleteProvider>
      </PrivateRoute>
    ),
    key: 'financeiro conferencia',
  },
  {
    path: ROUTE_FINANCEIRO_CONCILIACAO,
    element: (
      <PrivateRoute>
        <ConciliacaoCliente />
      </PrivateRoute>
    ),
    key: 'financeiro conciliacao',
  },
  {
    path: ROUTE_USUARIOS_FERIAS,
    element: (
      <PrivateRoute>
        <ControleFerias />
      </PrivateRoute>
    ),
    key: 'controle ferias',
  },
  {
    path: ROUTE_CONFIGURACOES_USUARIO,
    element: (
      <PrivateRoute>
        <ConfigUsuario />
      </PrivateRoute>
    ),
    key: 'config usuario',
  },
  {
    path: ROUTE_RELATORIO_ATENDIMENTO_POR_EQUIPE,
    element: (
      <PrivateRoute>
        <AtendimentoPorEquipe />
      </PrivateRoute>
    ),
    key: 'relatorio atendimento por equipe',
  },
  {
    path: ROUTE_RELATORIO_ATENDIMENTO_POR_CLIENTE,
    element: (
      <PrivateRoute>
        <AtendimentoPorCliente />
      </PrivateRoute>
    ),
    key: 'relatorio atendimento por cliente',
  },
  {
    path: ROUTE_RELATORIO_PICO_ATENDIMENTO,
    element: (
      <PrivateRoute>
        <PicoDeAtendimentos />
      </PrivateRoute>
    ),
    key: 'relatorio pico atendimento',
  },
  {
    path: ROUTE_RELATORIO_CLIENTES_DATA_CADASTRO,
    element: (
      <PrivateRoute>
        <ListagemClientesPorDtCadastro />
      </PrivateRoute>
    ),
    key: 'relatorio clientes data cadastro',
  },
  {
    path: ROUTE_RELATORIO_ATENDIMENTO_POR_TIPO,
    element: (
      <PrivateRoute>
        <AtendimentoPorTipo />
      </PrivateRoute>
    ),
    key: 'relatorio atendimento por tipo',
  },
];

export default routes;

import api from '../api.js';

export async function GetClientes(filtros) {
  try {
    const response = await api.get('/v1/clientes', { params: filtros });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetCliente(id) {
  try {
    const response = await api.get(`/v1/clientes/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetAlertasClienteFetch(id){
  try {
    const response = await api.get(`/v1/alerta_clientes/${id}`)
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetUfs() {
  try {
    const response = await api.get('/v1/uf');
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetCidades(uf) {
  try {
    const response = await api.get('/v1/cidades', {
      params: {
        uf,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetCidade(filtros){
  try {
    const response = await api.get('/v1/buscar_cidade', { params: filtros });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

import { useContext, useState } from 'react';
import UsuariosOnline from './usuarios-online';
import Notification from './notification';
import './style.css';
import { NotificacoesContext } from '../../context/notificacoesContext.jsx';
import { FaBell, FaUsers } from 'react-icons/fa6';
import { SituacaoEquipeContext } from '../../context/equipeContext.jsx';
import { ST__ULT_REQ_SIT_EQUIPE } from '../../constants/storagekey.js';

function BtnLaterais() {
  const { notificacoes } = useContext(NotificacoesContext);
  const {setManualRefresh} = useContext(SituacaoEquipeContext);

  const [areaOpen, setAreaOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [usuariosOnlineOpen, setUsuariosOnlineOpen] = useState(false);

  const RequestAtualizacaoSituacaoEquipe = () => {
    const time = new Date().getTime();
    const lastRequestTime = parseInt(localStorage.getItem(ST__ULT_REQ_SIT_EQUIPE), 10) || 0;
    const diff = time - lastRequestTime;
    if(diff > 1000 * 60 * 5){
      setManualRefresh();
      return;
    }    
  }

  const toogleUsuariosScreen = () => {
    if (areaOpen && !usuariosOnlineOpen && notificationOpen) {
      setNotificationOpen(false);
      setUsuariosOnlineOpen(true);
    }
    if (areaOpen && usuariosOnlineOpen && !notificationOpen) {
      setUsuariosOnlineOpen(false);
      setAreaOpen(false);
    }
    if (!areaOpen && !usuariosOnlineOpen) {
      setAreaOpen(true);
      setUsuariosOnlineOpen(true);
      RequestAtualizacaoSituacaoEquipe();
    }
    if (areaOpen && !usuariosOnlineOpen) {
      setUsuariosOnlineOpen(true);
      RequestAtualizacaoSituacaoEquipe();
    }
  };

  const toogleNotificacoesScreen = () => {
    if (areaOpen && !notificationOpen && usuariosOnlineOpen) {
      setUsuariosOnlineOpen(false);
      setNotificationOpen(true);
    }
    if (areaOpen && notificationOpen && !usuariosOnlineOpen) {
      setNotificationOpen(false);
      setAreaOpen(false);
    }
    if (!areaOpen && !notificationOpen) {
      setAreaOpen(true);
      setNotificationOpen(true);
    }
    if (areaOpen && !notificationOpen) {
      setNotificationOpen(true);
    }
  };

  return (
    <>
      <button
        className={`btn-laterais ${areaOpen ? 'btn-laterais-open' : ''} primeiro ${notificacoes.length ? 'qty' : ''}`}
        onClick={toogleNotificacoesScreen}
        data-qty={notificacoes.length}
      >
        <FaBell className={`${notificacoes.length ? 'fa-shake' : ''}`}/>
      </button>

      <button className={`btn-laterais ${areaOpen ? 'btn-laterais-open' : ''} segundo `} onClick={toogleUsuariosScreen}>
        <FaUsers />
      </button>
      <div className={`area ${areaOpen ? 'area-open' : ''}`}>
        <UsuariosOnline open={usuariosOnlineOpen} />
        <Notification open={notificationOpen} />
      </div>
    </>
  );
}

export default BtnLaterais;
